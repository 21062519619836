
/* stylelint-disable indentation, block-opening-brace-space-before, at-rule-empty-line-before, max-empty-lines, declaration-block-semicolon-newline-after */

.aspect-ratio-box-inner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .flex-row,
 	.wrapper {
        height: 100%;
    }

    &.fixed-program-aspect-ratio {
        display: flex;
        align-items: center;
        background-size: cover;
        background-position: center;
    }
}
