.default-asset-placeholder {
	object-fit: cover;
	object-position: center;
}

// Override css styles when skeleton is load, this happens on incentive promo
.default-INCENTIVE {
	img {
		max-width: 45%;
	}
}
