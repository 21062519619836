/* stylelint-disable unit-no-unknown, property-no-unknown, scss/at-rule-no-unknown */
div.ws-specials {
	container: promo-text-wrapper / inline-size;

	sup {
		top: -0.3em;

		&.reduced-dollar-sign {
			top: -1em;
			font-size: 38%;
		}
	}
}
