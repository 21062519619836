/* no-descending-specificity */
.ws-specials {
	.slick-slide {
		z-index: 998;
	}

	.slick-active.slick-current {
		z-index: 999;
	}

	.slick-slider.carousel,
	.carousel .slick-list,
	.single-promo-container {
		height: 100%;
		min-height: unset;
	}

	.slick-track {
		height: auto;
	}

	.carousel .slick-list {
		position: relative;
		z-index: 1;
	}

	// Fill vertical with slide//
	.slick-track,
	.single-promo-container {
		display: flex !important;
	}

	.slide-type-image {
		// Disclaimer button absolute positioning//
		position: relative;

		.custom-asset,
		& > a {
			width: 100%;
		}

		img.no-destination-url {
			cursor: default;
		}
	}

	.asset-disclaimer {
		z-index: 2;
	}

	// Fill vertical with slide
	.slick-slide,
	.single-promo {
		height: 100%;

		& > div {
			height: 100%;
			display: flex;
			flex-direction: column;

			& > div {
				flex-grow: 1;
				display: flex;
			}
		}
	}

	.single-promo {
		width: 100%;
	}

	.no-image-container {
		min-width: 150px;
		width: 50%;
		height: 80%;
		margin: auto 0;

		img {
			width: 50%;
		}
	}

	.no-image-container-mobile {
		min-width: 150px;
		width: 45%;

		img {
			width: 45%;
		}

		h2 {
			padding-bottom: 5px !important;
		}

		.h3 {
			font-size: clamp(0.8rem, 2vw, 3rem) !important;
		}
	}

	// Removing arrow in details link
	.btn.btn-link:before {
		display: none;
	}
}

// Due to our height calculations, composer doesn't have height when it's JS is parsed
.ddc-composer {
	.ws-specials {
		height: 100% !important;
		min-height: 30px;
	}
}

.fixed-program-aspect-ratio-blurred-box {
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 1;
}

.details-modal,
.dock-right {
	.close-modal {
		position: absolute;
		right: 15px;
		top: 15px;
	}
}
