// Hide the print component when the user does not enable printing
.print-view-details {
	display: none;
}

@media all {
	.print-view-details {
		overflow: hidden;
		height: 0;
		display: none;
	}
}

@media print {
	.top-wrapper {
		padding: 50px 40px 0;
	}

	.image4x3 {
		padding: 0 125px;
	}

	.print-view-details {
		overflow: visible;
		display: block;
		padding: 60px 40px 40px 40px;

		.start-end-date {
			font-weight: 400;
			font-size: 12.25px;
			line-height: 18px;
		}

		.heading,
		.discount,
		.applicable-trims,
		.disclaimer-title {
			font-weight: 600;
			font-size: 17.5px;
			line-height: 22px;
			padding-top: 8px;
			padding-bottom: 4px;
		}

		.applicable-trims {
			padding-top: 0;
		}

		.heading {
			padding: 8px 0;
			padding-top: 0;
		}

		.discount {
			padding: 8px 0;
		}

		.description,
		.offer-details {
			font-weight: 400;
			font-size: 14px;
			line-height: 21px;
			padding-top: 4px;
			padding-bottom: 4px;
		}

		.additional-details,
		.coupon-code {
			font-weight: 400;
			font-size: 12.25px;
			line-height: 18px;
			padding: 4px 0;
		}

		.disclaimer-title {
			padding: 18px 0;
		}

		.coupon,
		.details {
			font-weight: 600;
		}
	}

	.dealership {
		font-size: 24px;
		font-weight: bold;
		margin: 0 0 4px 0;
	}

	.address1 {
		margin-top: 8px;
	}

	.phone {
		margin-top: 8px;
	}

	.underline {
		width: 30%;
		border-bottom: 1px solid;
		margin: 20px 0;
	}
}

.cta-print {
	cursor: pointer;
}
